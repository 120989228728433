@import "_reset";
@import "_media-queries";
@import "_font-awesome";
@import "_slick";
@import "_lity";
@import "_photoswipe";
@import "_photoswipe-default-skin";

$text: black;
$text-hover: lighten($text, 25%);
$accent: #ccc;
$back: white;
$back-hover: darken($back, 10%);

$error: red;
$success: green;

// TYPOGRAPHY

html {
	font-size: 13px;

	@include min-screen(600px) {
		font-size: 14px;
	}

	@include min-screen(900px) {
		font-size: 15px;
	}
}

body {
	font-family: "Helvetica Neue", "Arial", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;
	line-height: 1.35em;
	text-align: left;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 3rem;
	line-height: 1.15em;
	margin-top: -0.5rem;
	margin-bottom: 1rem;
}

h2 {
	font-size: 2rem;
	line-height: 1.2em;
	margin-top: -0.15rem;
	margin-bottom: 1rem;
}

h3 {
	font-size: 1.4rem;
	line-height: 1.25em;
	margin-top: -0.1rem;
	margin-bottom: 1rem;
}

h4, h5, h6 {
	font-weight: 700;
	margin-bottom: 0.75rem;
}

p, ul, ol {
	margin-bottom: 0.85em;
}

strong, b {
	font-weight: 700;
}

a {
	color: inherit;
	text-decoration: none;
	outline: none;
}

em, i {
	font-style: italic;
}

input, textarea {
	font-family: "Helvetica Neue", "Arial", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;
	line-height: 1.35em;
}

.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.text {
	figure {
		display: block;
		width: 100%;
		height: auto;

		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}

		&.left {
			img {
				margin-left: 0;
			}
		}

		&.right {
			img {
				margin-right: 0;
			}
		}

		&.video {
			position: relative;
			padding-top: 56.25%;

			iframe {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}
	}
	
	*:last-child {
		margin-bottom: 0;
	}

	p > a:not(.tag) {
		border-bottom: 2px solid $back-hover;
		transition: border-color 300ms;

		&:hover {
			border-color: $accent;
		}
	}
}









// BUTTONS, TAGS & LINKS

div.button {
	display: inline-block;
	vertical-align: top;
	margin: 0 0 0.35rem 0;

	& > a,
	& > span {
		display: inline-block;
		position: relative;
		white-space: nowrap;
		font-size: 1rem;
		line-height: 1.35em;
		transition: all 200ms;

		span, input {
			display: inline-block;
			margin: 0;
			padding: 0;
			border: none;
			outline: none;
			color: inherit;
			background-color: transparent;
			cursor: pointer;
		}

		i.fa {
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			width: 1.5em;
			font-size: inherit;
			line-height: inherit;
			transform: translateX(0);
			transition: transform 400ms;
			text-align: center;
		}

		&:hover i.fa {
			transform: translateX(0.1em);
		}

		i.fa + span,
		i.fa + input {
			padding: 0 1.65em 0 0;
		}
	}

	&.icon-left > a,
	&.icon-left > span {
		i.fa {
			left: 0;
			right: auto;
		}

		&:hover i.fa {
			transform: translateX(-0.1em);
		}

		i.fa + span,
		i.fa + input {
			padding: 0 0 0 1.65em;
		}
	}

	&.ghost > a,
	&.ghost > span,
	&.filled > a,
	&.filled > span {
		border: 1px solid $text;
		border-radius: 2px;

		i.fa {
			right: 0.265em;
			line-height: 2.4em;
		}

		span, input {
			line-height: 1em;
			padding: 0.68em 0.75em 0.72em 0.75em;
		}

		i.fa + span,
		i.fa + input {
			padding: 0.68em 2.15em 0.72em 0.75em;
		}
	}

	&.ghost > a,
	&.ghost > span {
		color: inherit;
		background-color: transparent;

		&:hover {
			color: $back;
			background-color: $text;
		}
	}

	&.filled > a,
	&.filled > span {
		color: $back;
		background-color: $text;

		&:hover {
			color: $back-hover;
			background-color: $text-hover;
		}
	}

	&.ghost.icon-left > a,
	&.ghost.icon-left > span,
	&.filled.icon-left > a,
	&.filled.icon-left > span {
		i.fa {
			left: 0.265em;
			right: auto;
		}

		i.fa + span,
		i.fa + input {
			padding-left: 2.15em;
			padding-right: 0.75em;
		}
	}

	&.large > a,
	&.large > span {
		font-size: 1.325rem;
		line-height: 1.25em;
	}

	&.large.ghost > a,
	&.large.ghost > span,
	&.large.filled > a,
	&.large.filled > span {
		span,
		input,
		i.fa + span,
		i.fa + input {
			padding-top: 0.67em;
			padding-bottom: 0.73em;
		}
	}
}

a.tag,
span.tag {
	display: inline-block;
	vertical-align: top;
	color: inherit;
	border: 1px solid $text;
	border-radius: 1.2em;
	margin: 0 0 0.35rem 0;
	padding: 4px 8px 4px 8px;
	font-size: 13px;
	line-height: 16px;
	transition: color 300ms, background-color 300ms;
	white-space: nowrap;

	&:hover {
		color: $back;
		background-color: $text;
	}

	&.active {
		color: $back;
		background-color: $text;
	}
}

// GRAPHIC

.icon {
	font-size: 1.5rem;
	line-height: 1em;

	&.circle,
	&.border {
		width: 3rem;
		height: 3rem;
		font-size: 1.15rem;
		line-height: 2.95rem;
		border-radius: 100%;
		text-align: center;
	}

	&.circle {
		color: $back;
		background-color: $accent;
	}

	&.border {
		color: $accent;
		box-shadow: inset 0px 0px 0px 2px $accent;
	}
}










// LAYOUT

* {
	box-sizing: border-box;
}

.cf {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%max-width {
    max-width: 960px;
}

body {
	overflow-x: hidden;
}

header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 5rem;
	background-color: white;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	box-shadow: 0px -10px 5px 11px rgba(0,0,0,0.25);

	.site-logo {
		display: block;
		position: absolute;
		top: 1.25rem;
		left: 1rem;
		width: auto;
		height: 2.5rem;
		font-size: 1.85rem;
		line-height: 2.5rem;

		@include min-screen(1200px) {
			left: 1.5rem;
		}

		img {
			display: block;
			width: auto;
			height: 100%;
		}
	}

	nav {
		@include max-screen(899px) {
			position: fixed;
			top: 5rem;
			left: 100%;
			width: 100%;
			transform: translateX(0%);
			height: calc(100% - 5rem);
			overflow-x: hidden;
			overflow-y: auto;
			padding: 0 1rem 1rem 1rem;
			color: $back;
			background-color: $text;
			opacity: 0;
			transition: opacity 300ms, transform 0ms 300ms;

			& > ul.site-nav,
			& > div.lang-nav {
				opacity: 0;
				transition: opacity 300ms, transform 0ms 300ms;
			}

			& > ul.site-nav {
				transform: translateY(1rem);
				font-size: 1.4rem;
				line-height: 2.5rem;
				margin-bottom: 0;

				& > li {
					padding: 1rem 0 1.15rem 0;
					border-bottom: 1px solid $text-hover;

					&:last-child {
						border-bottom: none;
					}

					& > a > i.fa {
						color: $text-hover;
						margin-left: 0.5em;
					}

					& > ul {
						border-top: 1px dashed $text-hover;
						font-size: 1.2rem;
						line-height: 2.5rem;
						margin-top: 1.15rem;
						margin-bottom: 0;

						& > li {
							position: relative;
							margin-left: 2rem;

							&:first-child {
								margin-top: 1rem;
							}
						}
					}
				}
			}

			& > div.lang-nav {
				transform: translateY(1.5rem);
				margin-top: 2rem;
				margin-left: -1px;
				font-size: 1.25rem;
				line-height: 3.2rem;
				font-weight: 700;
				margin-bottom: 0;

				& > div {
					pointer-events: none;
					color: $text-hover;
					background-color: $back;

					i.fa {
						display: none;
					}
				}

				& > ul {
					display: inline-block;

					& > li {
						display: inline-block;

						& > a {
							color: $back;
							background-color: $text-hover;
							transition: all 300ms;

							&:hover {
								color: $text-hover;
								background-color: $back;
							}
						}
					}
				}

				& > div,
				& > ul > li > a {
					display: inline-block;
					text-align: center;
					text-transform: uppercase;
					width: 3.25rem;
					height: 3.25rem;
					border-radius: 100%;
					margin-right: 0.25rem;
				}
			}

			&.active {
				transform: translateX(-100%);
				opacity: 1;
				transition: opacity 200ms, transform 0ms;
				
				& > div.lang-nav,
				& > ul.site-nav {
					transform: translateY(0);
					opacity: 1;
					transition: opacity 450ms 100ms, transform 500ms;
				}
			}
		}

		@include min-screen(900px) {
			position: absolute;
			top: 1.825rem;
			right: 1rem;

			& > ul.site-nav {
				display: block;
				float: left;
				margin-bottom: 0;

				& > li {
					display: inline-block;
					position: relative;
					padding-bottom: 1.825rem;
					margin-left: 1rem;
					white-space: nowrap;

					&.active {
						color: $accent;
					}

					&.active:before,
					&.active-submenu:before {
						content: "";
						display: block;
						position: absolute;
						top: -1.85rem;
						left: 0;
						height: 0;
						border-top: 3px solid $accent;
					}

					&.active:before {
						width: 100%;
					}

					&.active-submenu:before {
						width: calc(100% - 0.825rem);
					}

					&:first-child {
						margin-left: 0;
					}

					& > a,
					& > span {
						transition: color 300ms;
					}

					& > span {
						cursor: default;
					}

					& > a > i.fa,
					& > span > i.fa {
						font-size: 0.85em;
					}

					& > ul {
						display: block;
						position: absolute;
						top: 3.175rem;
						left: 50%;
						margin-left: -0.4175rem;
						margin-bottom: 0;
						transform: translateX(-50%) translateY(-9999px);
						line-height: 2em;
						text-align: center;
						opacity: 0;
						color: $back;
						background-color: $text-hover;
						padding: 0.75rem 1.5rem;
						transition: opacity 200ms, transform 0ms 200ms;

						& > li {
							white-space: nowrap;
							transform: translateY(0.25rem);
							transition: transform 0ms 200ms linear;

							& > a {
								transition: color 200ms;
							}
						}
					}

					&:last-child > ul {
						left: auto;
						right: 0;
						transform: translateX(0) translateY(-9999px);
					}

					&:hover > ul {
						opacity: 1;
						transform: translateX(-50%) translateY(0);
						transition: opacity 300ms, transform 0ms;

						li {
							transform: translateY(0);
							transition: transform 500ms cubic-bezier(.1,.59,.26,.96);
						}
					}

					&:last-child:hover > ul {
						transform: translateX(0) translateY(0);
					}
				}
			}

			div.lang-nav {
				position: relative;
				display: block;
				float: right;
				margin-left: 1rem;
				margin-bottom: 0;
				width: 3.15rem;
				height: auto;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 0.8em;
				line-height: 2rem;

				& > div {
					position: absolute;
					top: -0.325rem;
					left: 0;
					width: 3.15rem;
					height: 2rem;
					padding-bottom: 1.5rem;
					cursor: pointer;
					color: $text;
					background-color: $accent;
					border-radius: 1rem;
					transition: all 300ms;

					span {
						display: block;
						position: absolute;
						top: 0;
						left: 0.75rem;
						color: inherit;
					}

					& > i.fa {
						display: block;
						position: absolute;
						top: 0;
						right: 0.5rem;
						font-size: 0.85rem;
						line-height: 2rem;
						color: inherit;
					}
				}

				& > ul {
					display: block;
					position: absolute;
					top: -9999px;
					left: 0;
					width: 3.15rem;
					height: auto;
					padding: 0;
					margin: 0;				
					text-align: center;
					opacity: 0;
					transition: opacity 200ms, top 0ms 200ms;

					li {
						display: block;
						transition: transform 200ms;

						&:nth-child(1) {
							transform: translateY(0.2rem);
						}

						&:nth-child(2) {
							transform: translateY(0.4rem);
						}

						&:nth-child(3) {
							transform: translateY(0.6rem);
						}

						&:nth-child(4) {
							transform: translateY(0.8rem);
						}

						&:nth-child(5) {
							transform: translateY(1rem);
						}

						&:nth-child(6) {
							transform: translateY(1.2rem);
						}

						&:nth-child(7) {
							transform: translateY(1.4rem);
						}

						&:nth-child(8) {
							transform: translateY(1.6rem);
						}

						&:nth-child(9) {
							transform: translateY(1.8rem);
						}

						&:nth-child(10) {
							transform: translateY(2rem);
						}

						a {
							display: block;
							width: 3.15rem;
							height: 2rem;
							padding: 0.25rem 0 0 0;
							border-radius: 1rem;
							line-height: 1.5rem;
							margin-top: 3px;
							color: $back;
							background-color: $text-hover;
							transition: all 300ms;

							&:hover {
								color: $text;
								background-color: $accent;
							}
						}
					}
				}

				&:hover {
					& > div {
						color: $back;
						background-color: $text-hover;

						&:hover {
							color: $text;
							background-color: $accent;
						}
					}

					& > ul {
						top: 1.675rem;
						opacity: 1;
						transition: opacity 200ms, top 0ms;

						li {
							transform: translateY(0);
							transition: transform 300ms cubic-bezier(.22,.49,.45,.88);
						}
					}
				}
			}
		}

		@include min-screen(1200px) {
			right: 1.5rem;
		}
	}

	#nav-switch {
		display: block;
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 3rem;
		height: 3rem;
		font-size: 1.4rem;
		line-height: 3rem;
		text-align: center;
		cursor: pointer;
		border-radius: 100%;
		background-color: transparent;

		.nav-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 2px;
			margin: 4px 0 0 -11px;
			border-radius: 1px;
			background-color: $text;
			opacity: 1;
			transition: opacity 0ms 100ms;

			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 2px;
				border-radius: 1px;
				background-color: $text;
			}
			
			&:before {
				opacity: 1;
				transform: translateY(-12px);
				transition: opacity 200ms 100ms, transform 200ms 100ms cubic-bezier(.15,.53,.41,.99);
			}

			&:after {
				opacity: 1;
				transform: translateY(-6px);
				transition: opacity 200ms 100ms, transform 200ms 100ms cubic-bezier(.15,.53,.41,.99);
			}

			&.active {
				opacity: 0;
				transition: opacity 0ms 150ms;
			}

			&.active:before {
				opacity: 0;
				transform: translateY(0px);
				transition: opacity 200ms 50ms, transform 150ms cubic-bezier(.4,.04,.81,.3);
			}

			&.active:after {
				opacity: 0;
				transform: translateY(0px);
				transition: opacity 200ms 50ms, transform 150ms cubic-bezier(.56,.04,.8,.31);
			}
		}

		.close-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 2px;
			margin: 4px 0 0 -11px;
			border-radius: 1px;
			background-color: transparent;
			opacity: 0;
			transition: opacity 0ms 100ms, transform 100ms;

			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 2px;
				border-radius: 1px;
				background-color: $text;
			}

			&:before {
				transform: rotate(0deg);
				transition: transform 100ms;
			}

			&:after {
				transform: rotate(0deg);
				transition: transform 100ms;
			}

			&.active {
				opacity: 1;
				transform: translateY(-6px);
				transition: opacity 0ms 150ms, transform 350ms 150ms cubic-bezier(.15,.5,.47,.97);
			}

			&.active:before {
				transform: rotate(-45deg);
				transition: transform 150ms 150ms;
			}

			&.active:after {
				transform: rotate(45deg);
				transition: transform 150ms 150ms;
			}
		}

		@include min-screen(900px) {
			display: none;
			visibility: hidden;
		}
	}
}

main {
	position: relative;
	margin-top: 5rem;
	min-height: calc(100vh - 5rem);

	@include max-screen(899px) {
		transform: translateY(0) scaleX(1);
		transform-origin: top center;
		transition: transform 200ms ease-out;

		&.inactive {
			transform: translateY(-1rem) scaleX(1.015);
			transition: transform 300ms ease-in;
		}
	}
}

footer {
	@extend .cf;
	background-color: $back-hover;

	.social {
		padding: 1rem;

		a {
			display: inline-block;
		}

		.icon {
			color: $back-hover;
			background-color: $accent;
			transition: background-color 300ms;

			&:hover {
				background-color: $text;
			}
		}

		@include min-screen(600px) {
			float: right;

			a {
				margin-left: 0.25rem;
			}
		}
	}

	.copyright {
		padding: 1.1rem 1rem 0.9rem 1rem;
		font-size: 0.8rem;
		line-height: 1rem;

		a {
			display: inline-block;
			border-bottom: 1px solid $accent;
			transition: border-color 300ms;

			&:hover {
				border-color: $text;
			}
		}

		* {
			margin-bottom: 0;
		}
	}

	.social + .copyright {
		@include min-screen(600px) {
			padding: 2.05rem 1rem 1.95rem 1rem;
		}
	}
}

.breadcrumbs {
	padding: 1rem;
	color: $text-hover;
	background-color: $back-hover;
	font-size: 0.85rem;
	line-height: 1.55rem;
	cursor: default;

	@include min-screen(1200px) {
		padding: 1rem 1.5rem;
	}

	ul {
		margin: 0;

		li {
			display: inline-block;
			margin: 0;

			& + li {
				margin-left: 0.5rem;
			}

			i.fa {
				margin-right: 0.5rem;
				opacity: .75;
			}
		}
	}

	span {
		cursor: default;
	}
}


















// MODULES

.module {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	
	&.separator:after {
		content: "";
		display: block;
		width: calc(100% - 2rem);
		max-width: calc(960px - 2rem);
		height: 0;
		margin: 0 auto;
		border-bottom: 1px solid $back-hover;
	}

	&:last-child:after {
		display: none;
	}

	& > .content {
		@extend .cf;
		@extend %max-width;
		margin: 0 auto;
		padding: 5rem 0 5rem 0;
	}

	
}

.module.text {
	& > .content {
		& > .text {
			max-width: 46rem;
			margin: 0 auto;
			padding: 0 1rem;
			font-size: 1.15rem;
			line-height: 1.4em;
		}
	}
}

.module.columns {
	& > .content {
		& > .column {
			position: relative;
			width: 100%;
			max-width: 30rem;
			margin: 0 auto 5rem auto;
			padding: 0 1rem;
			@extend .cf;

			&:last-child {
				margin-bottom: 0;
			}

			&.size-1-2 {
				@include min-screen(600px) {
					width: 50%;
					max-width: none;
					float: left;
					margin-bottom: 0;
				}
			}

			&.size-1-3 {
				@include min-screen(900px) {
					width: 33.333%;
					max-width: none;
					float: left;
					margin-bottom: 0;
				}
			}

			&.size-1-4 {
				@include min-screen(900px) {
					width: 25%;
					max-width: none;
					float: left;
					margin-bottom: 0;
				}
			}

			text-align: center;

			&.align-left {
				text-align: left;
			}

			.icon {
				margin-bottom: 1rem;
			}

			&.icon-aside {
				.icon {
					position: absolute;
					top: 0;
					left: 1rem;
				}

				.text {
					float: right;
					width: 80%;
					max-width: calc(100% - 4.5rem);
				}
			}
		}
	}
}

.module.image-text,
.module.slideshow-text {
	& > .content {
		.text {
			width: 100%;
			padding: 0 1rem;

			@include max-screen(599px) {
				text-align: center;
				max-width: 30rem;
				margin-left: auto;
				margin-right: auto;
			}

			@include min-screen(600px) {
				width: 50%;
				float: left;

				&.float-right {
					float: right;
				}
			}

			@include min-screen(800px) {
				padding-right: 4rem;
			}
		}

		.image,
		.slideshow {
			width: 100%;
			margin-top: 1.75rem;
			padding: 0 1rem;

			@include min-screen(600px) {
				width: 50%;
				float: right;
				margin-top: 0.35rem;
			}
		}
	}
}

.module.image-text {
	& > .content {
		.image {
			img {
				display: block;
				width: auto;
				max-width: 100%;
				height: auto;
				margin: 0 auto;
			}
		}
	}
}

.module.slideshow-text {
	& > .content {
		.slideshow {
			position: relative;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}

.module.gallery {
	color: $back;
	background-color: $text;

	& > .content {
		max-width: none;
		padding: 0;
		border-bottom: none;

		figure {
			display: block;
			position: relative;			
			float: left;

			&.size-1-3 {
				width: 33.333%;
			}

			&.size-1-4 {
				width: 50%;
				@include min-screen(600px) {
					width: 25%;
				}
			}

			&.size-1-6 {
				width: 33.333%;
				@include min-screen(600px) {
					width: 16.666%;
				}
			}

			&.size-1-8 {
				width: 25%;
				@include min-screen(600px) {
					width: 12.5%;
				}
			}

			img {
				display: block;
				width: 100%;
				height: auto;
				opacity: 1;
				transition: opacity 300ms;
			}

			i.fa {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				font-size: 1.75rem;
				transform: translateX(-50%) translateY(50%);
				opacity: 0;
				transition: opacity 200ms, transform 200ms;
			}

			&:hover {
				img {
					opacity: .75;
				}

				i.fa {
					transform: translateX(-50%) translateY(-50%);
					opacity: 1;
				}
			}
		}

	}
}

.module.slideshow {
	position: relative;
	color: $back;
	background-color: $text;
	
	& > .content {
		max-width: none;
		padding: 0;
		border-bottom: none;

		.text {
			position: absolute;
			width: 100%;
			max-width: 42rem;
			height: auto;
			padding: 1rem 1rem;

			@include min-screen(1200px) {
				padding: 1.5rem 1.5rem;
			}
		}

		.slide {
			position: relative;
			width: 100%;
			min-height: 400px;
			height: 80vh;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			& > .text {
				transform: translateY(3rem);
				opacity: 0;
				transition: opacity 200ms, transform 800ms;
			}

			&.slick-center,
			&.slick-current,
			&.slick-active {
				.text {
					transform: translateY(0rem);
					opacity: 1;
					transition: opacity 1200ms, transform 800ms;
				}
			}
		}
	}

	&.layout-centered .text,
	&.layout-centered-nav .text,
	&.layout-centered-pag .text {
		top: 50%;
		left: 50%;
		text-align: center;
	}

	&.layout-left-pag .text,
	&.layout-left-nav .text,
	&.layout-left-nav .text {
		left: 0;
		text-align: left;
	}

	&.layout-left-pag .text,
	&.layout-left-nav .text {
		top: 50%;
	}

	&.layout-bottom-nav .text {
		bottom: 0;
	}
}

.module.blogposts {
	position: relative;

	&.grid {
		color: $back;

		& > .content {
			padding: 0;
			max-width: none;

			.posts {
				@extend .cf;
				padding: 0;

				.post {
					display: block;
					position: relative;
					width: 100%;
					height: auto;
					background-color: $text;
					outline: none;

					&.size-1-2 {
						@include min-screen(600px) {
							width: 50%;
							float: left;
						}
					}

					&.size-1-3 {
						@include min-screen(800px) {
							width: 33.333%;
							float: left;
						}
					}

					&.size-1-4 {
						@include min-screen(600px) {
							width: 50%;
							float: left;
						}
						@include min-screen(900px) {
							width: 25%;
							float: left;
						}
					}

					&.size-1-6 {
						@include min-screen(800px) {
							width: 33.333%;
							float: left;
						}
						@include min-screen(1200px) {
							width: 16.666%;
							float: left;
						}
					}

					& > a {
						display: block;
						position: relative;
						width: 100%;
						height: auto;
						opacity: .85;
						transition: opacity 200ms;

						img {
							display: block;
							width: 100%;
							height: auto;
						}
					}

					&:hover > a {
						opacity: .7;
					}

					& > .text {
						pointer-events: none;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						max-width: 20rem;
						height: auto;
						text-align: center;
						outline: none;

						span.post-date {
							display: inline-block;
							margin-bottom: 0.75rem;
						}

						.post-tags > a.tag {
							pointer-events: auto;
							border-color: $back;

							&:hover {
								color: $text;
								background-color: $back;
							}
						}
					}
				}
			}
		}
	}

	&.slideshow {
		& > .content {
			.slide {
				height: 400px;
				min-height: 400px;

				& > a {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
					opacity: .85;
					transition: opacity 200ms;
					pointer-events: none;
				}

				.text {
					span.post-date {
						opacity: 0;
						transition: opacity 200ms;
					}

					h3 {
						opacity: 0;
						transform: translateY(4rem);
						transition: opacity 200ms, transform 0ms 200ms;
					}

					div.post-tags {
						opacity: 0;
						transform: translateY(1rem);
						transition: opacity 200ms, transform 0ms 200ms;
					}
				}

				&.slick-center {
					& > a {
						pointer-events: auto;
					}

					.text {
						span.post-date {
							opacity: 1;
							transition: opacity 800ms 100ms;
						}

						h3 {
							opacity: 1;
							transform: translateY(0);
							transition: opacity 500ms 100ms, transform 500ms cubic-bezier(.19,.49,.5,.99);
						}

						div.post-tags {
							opacity: 1;
							transform: translateY(0);
							transition: opacity 1000ms 300ms, transform 400ms 300ms;
						}
					}
				}
			}
		}
	}

	&.list {
		.posts {
			padding: 0 1rem;

			a.post {
				display: block;
				position: relative;
				font-size: 0;
				line-height: 0;
				padding: 0.85rem 0;
				border-bottom: 1px solid $back-hover;

				&:first-child {
					padding-top: 0;
				}

				& > span, & > i.fa {
					display: block;
					font-size: 1rem;
					line-height: 1.35em;

					@include min-screen(400px) {
						display: inline-block;
					}
				}

				& > span.post-date {
					width: 100%;
					white-space: nowrap;
					@include min-screen(400px) {
						width: 33.333%;
					}
					@include min-screen(600px) {
						width: 25%;
					}
				}

				& > span.post-title {
					width: 100%;
					@include min-screen(400px) {
						white-space: nowrap;
						width: 66.666%;
					}
					@include min-screen(600px) {
						white-space: nowrap;
						width: 70%;
					}
				}

				& > i.fa {
					display: none;
					@include min-screen(600px) {
						display: inline-block;
						width: 5%;
						text-align: right;
						padding-right: 0.5rem;
					}
				}
			}
		}
	}
}

.module {
	.slider-navigation {
		i.fa {
			display: block;
			position: absolute;
			top: 50%;
			margin-top: -1.25rem;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 100%;
			box-shadow: inset 0px 0px 0px 1px $back;
			text-align: center;
			font-size: 1.5rem;
			line-height: 2.45rem;
			cursor: pointer;

			&.prev-slide {
				left: 1rem;
				padding-right: 0.1em;

				@include min-screen(1200px) {
					left: 1.5rem;
				}
			}

			&.next-slide {
				right: 1rem;
				padding-left: 0.1em;

				@include min-screen(1200px) {
					right: 1.5rem;
				}
			}
		}
	}

	.slider-pagination {
		position: absolute;
		bottom: 0.75rem;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		height: auto;

		ul {
			padding: 0;
			margin: 0;
		}

		li {
			display: inline-block;
			margin: 0;

			button {
				display: block;
				position: relative;
				width: 24px;
				height: 24px;
				padding: 0;
				margin: 0;
				color: transparent;
				background: transparent;
				border: none;
				outline: none;
				cursor: pointer;

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 11px;
					left: 11px;
					width: 10px;
					height: 10px;
					border-radius: 100%;
					transform: translateX(-5px) translateY(-5px);
					border: 1px solid $back;
					background-color: transparent;
					transition: background-color 300ms;
				}

				&:hover:before {
					background-color: $back;
				}
			}

			&.slick-active {
				button:before {
					background-color: $back;
				}
			}
		}
	}

	&.slideshow {
		&.layout-centered .slider-navigation {
			& .prev-slide {
				@include max-screen(599px) {
					top: auto;
					bottom: 1rem;
					margin-top: 0;
				}
			}

			& .next-slide {
				@include max-screen(599px) {
					top: auto;
					bottom: 1rem;
					margin-top: 0;
				}
			}
		}

		&.layout-centered .slider-pagination {
			@include max-screen(599px) {
				bottom: 1.35rem;
			}
		}

		&.layout-centered-nav .slider-navigation {
			& .prev-slide {
				@include max-screen(599px) {
					top: auto;
					bottom: 1rem;
					left: auto;
					right: 50%;
					margin-top: 0;
					margin-right: 0.5rem;
				}
			}

			& .next-slide {
				@include max-screen(599px) {
					top: auto;
					bottom: 1rem;
					right: auto;
					left: 50%;
					margin-top: 0;
					margin-left: 0.5rem;
				}
			}
		}

		&.blogposts.layout-centered .slider-navigation,
		&.blogposts.layout-centered-nav .slider-navigation {
			& .prev-slide {
				@include min-screen(600px) {
					left: 10%;
					margin-left: -3.5rem;
				}

				@include min-screen(900px) {
					left: 20%;
				}
			}

			& .next-slide {
				@include min-screen(600px) {
					right: 10%;
					margin-right: -3.5rem;
				}

				@include min-screen(900px) {
					right: 20%;
				}
			}
		}

		&.layout-left-pag .slider-pagination {
			left: 0.55rem;
			transform: none;

			@include min-screen(600px) {
				top: 50%;
				bottom: auto;
				left: auto;
				right: 0.55rem;
				transform: translateY(-50%);
			}

			@include min-screen(1200px) {
				right: 1.05rem;
			}

			li {
				@include min-screen(600px) {
					display: block;
				}
			}
		}

		&.layout-left-nav .slider-navigation {
			& .prev-slide {
				top: auto;
				bottom: 1rem;
				left: 1rem;
				margin-top: 0;

				@include min-screen(1200px) {
					left: 1.5rem;
					bottom: 1.5rem;
				}
			}

			& .next-slide {
				top: auto;
				bottom: 1rem;
				left: 1rem;
				right: auto;
				margin-top: 0;
				margin-left: 3rem;

				@include min-screen(1200px) {
					left: 1.5rem;
					bottom: 1.5rem;
				}
			}
		}

		&.blogposts.layout-left-nav .slider-navigation {
			& .prev-slide {
				@include min-screen(600px) {
					left: 10%;
					margin-left: 1rem;
				}
				@include min-screen(900px) {
					left: 20%;
				}
			}

			& .next-slide {
				@include min-screen(600px) {
					left: 10%;
					margin-left: 4rem;
				}
				@include min-screen(900px) {
					left: 20%;
				}
			}
		}

		&.layout-bottom-nav .slider-navigation {
			& .prev-slide {
				margin-top: 0;

				@include max-screen(599px) {
					top: 1rem;
				}

				@include min-screen(600px) {
					top: auto;
					bottom: 1rem;
					left: auto;
					right: 0;
					margin-right: 4rem;
				}

				@include min-screen(1200px) {
					margin-right: 4.5rem;
					bottom: 1.5rem;
				}
			}

			& .next-slide {
				margin-top: 0;

				@include max-screen(599px) {
					top: 1rem;
					left: 4rem;
					right: auto;
				}

				@include min-screen(600px) {
					top: auto;
					right: 0;
					bottom: 1rem;
					margin-right: 1rem;
				}

				@include min-screen(1200px) {
					margin-right: 1.5rem;
					bottom: 1.5rem;
				}
			}
		}

		&.blogposts.layout-bottom-nav .slider-navigation {
			& .prev-slide {
				@include min-screen(600px) {
					right: 10%;
				}

				@include min-screen(900px) {
					right: 20%;
				}
			}

			& .next-slide {
				@include min-screen(600px) {
					right: 10%;
				}

				@include min-screen(900px) {
					right: 20%;
				}
			}
		}
	}
}

.module.table {
	& > .content {
		.table {
			padding: 0 1rem;

			table {
				width: 100%;
				margin-top: -0.85rem;
				margin-bottom: 0.75rem;

				tr {
					border-bottom: 1px solid $back-hover;

					th, td {
						padding: 0.85rem;

						&:first-child {
							padding-left: 0;
						}

						&:last-child {
							padding-right: 0;
						}
					}
				}

				thead {
					tr {
						th {
							font-weight: 700;
						}
					}
				}

				tbody {
					tr {
						td {

						}
					}
				}
			}
		}
	}
}

.module.form {
	& > .content {
		form {
			position: relative;
			text-align: center;
			font-size: 0;
			margin-top: -1rem;

			.form-field, .form-checkbox {
				display: inline-block;
				position: relative;
				width: 100%;
				height: auto;
				vertical-align: top;
				padding: 1rem;
				text-align: left;
				font-size: 1rem;

				&.size-1-2 {
					@include min-screen(600px) {
						width: 50%;
						//float: left;
					}
				}

				span {
					display: block;
					width: 100%;
					margin-top: 0.65rem;
					font-size: 0.85rem;
					line-height: 1.35em;
				}

				span.error-text {
					color: $error;
				}
			}

			.form-field {
				input, textarea {
					display: block;
					width: 100%;
					height: auto;
					height: auto;
					margin: 0;
					padding: 0.75rem 0.05rem;
					background-color: transparent;
					border: none;
					border-bottom: 1px solid $back-hover;
					outline: 0px none transparent;
					box-shadow:none;
					color: $text;
					transition: border 300ms;
				}

				textarea {
					min-width: 100%;
					max-width: 100%;
					height: 2.85rem;
					min-height: 2.85rem;
					transition: height 150ms;
					overflow: hidden;

					&:focus {
						height: 5.6rem;
					}

					&:valid {
						height: 5.6rem;
					}
				}

				input[type="checkbox"] {
					display: inline;
					width: auto;
					height: auto;
					margin: 0 0.25rem 0 0;
					vertical-align: 5%;

					& + label {
						display: inline;
						color: $text;
					}
				}

				label {
					display: inline-block;
					position: absolute;
					top: 1.75rem;
					left: 1.05rem;
					margin: 0;
					transform: translateY(0) scale(1);
					transform-origin: top left;
					color: $back-hover;
					transition: color 200ms, transform 150ms;
					cursor: text;

				}

				input:focus + label,
				textarea:focus + label {
					transform: translateY(-1.55rem) scale(0.915);
				}

				input.not-empty + label,
				textarea.not-empty + label {
					transform: translateY(-1.55rem) scale(0.915);
				}

				input:valid:focus + label
				textarea:valid:focus + label {
					color: $text;
				}

				&.error {
					input, textarea {
						border-color: red;
					}
				}
			}

			.form-checkbox {
				input, label {
					display: inline;
					cursor: pointer;
				}

				input {
					vertical-align: 5%;
					margin: 0 0.25rem 0 0;
				}

				label {
					font-size: 0.85rem;
				}

				span {
					margin-top: 0.275rem;
				}
			}

			.form-submit {
				margin-top: 3rem;

				&.disabled {
					display: none;
					pointer-events: none;
				}
			}

			.uniform__potty {
				position: absolute;
				left: -9999px;
			}
		}

		.form-message {
			width: 100%;
			height: auto;
			padding: 0 1rem;
			margin-bottom: 3rem;
			
			text-align: center;
			font-size: 1rem;

			
			span {
				display: block;
				width: 100%;
				height: auto;
				padding: 1rem;
				border-radius: 2px;
				color: white;
			}

			&.success span {
				background-color: $success;
			}

			&.error span {
				background-color: $error;
			}
		}
	}
}

.module.map {
	& > .content {
		padding: 0;
		max-width: none;
		min-height: 400px;
		height: 50vh;

		& > div,
		& > iframe {
			width: 100%;
			height: 100%;
			background-color: $back-hover;
		}
	}
}

.module.documents {
	& > .content {

		.files-list {
			padding: 0 1rem;
			margin-top: -0.85rem;

			a.file {
				display: block;
				width: 100%;
				padding: 0.85rem 0;
				border-bottom: 1px solid $back-hover;
				@extend .cf;
				transition: background-color 300ms;

				&:hover {
					background-color: $back-hover;
					transition: background-color 50ms;
				}

				& > div.file-name {
					display: inline-block;
					float: left;
					width: auto;

					i.fa, span {
						display: inline;
					}

					i.fa {
						text-align: center;
						margin: 0 0.5rem 0 0.5rem;
					}
				}

				& > div.file-download {
					display: none;

					@include min-screen(600px) {
						display: inline-block;
						float: right;
					}

					span {
						font-size: 0.8rem;
						text-transform: uppercase;
						margin-right: 0.85rem;
					}

					i.fa {
						text-align: center;
						margin: 0 0.5rem 0 0;
					}
				}
			}
		}
	}
}

.module.events {
	& > .content {

		.events {
			font-size: 0;
			line-height: 0;

			@include min-screen(1200px) {
				//padding: 0 1rem;
			}

			.event-container {
				display: inline-block;
				position: relative;
				vertical-align: top;
				width: 100%;
				padding: 0.5rem;
				font-size: 1rem;
				line-height: 1.35em;

				@include min-screen(900px) {
					width: 33.333%;
				}

				.event {
					position: relative;
					width: 100%;
					height: auto;

					a.event-link {
						position: relative;
						display: block;
						width: 100%;
						height: auto;
						background-color: $back;

						.event-header {
							position: relative;
							width: 100%;
							height: 4.5rem;
							color: $back;
							background-color: $text;
							border-radius: 2px;

							&:after {
								content: "";
								display: block;
								position: absolute;
								top: 100%;
								left: 1.35rem;
								width: 0;
								height: 0;
								margin: 0;
								border-left: 0.65rem solid transparent;
								border-right: 0.65rem solid transparent;
								border-top: 0.65rem solid $text;
							}

							.event-start {
								position: relative;
								float: left;
								width: 4rem;
								height: 100%;
								text-align: center;
								border-right: 1px dashed $back;

								span {
									position: absolute;
									display: block;
									width: 100%;
								}

								span:first-child {
									height: auto;
									font-size: 1.875rem;
									line-height: 1em;
									letter-spacing: -0.005em;
									top: 0.725rem;
									left: 0;
								}

								span:last-child {
									height: auto;
									font-size: 0.85rem;
									font-weight: 700;
									line-height: 1em;
									letter-spacing: 0.05em;
									text-transform: uppercase;
									bottom: 1rem;
									left: 0;
								}
							}

							.event-data {
								width: calc(100% - 4rem);
								float: right;
								padding: 0.5rem 0.375rem;
								pointer-events: none;

								span.tag {
									text-transform: uppercase;
									border-color: $back;
									padding: 0.325em 0.475em 0.300em 0.475em;

									&.active {
										color: $text;
										background-color: $back;
									}
								}
							}
						}	

						.event-back {
							position: relative;
							width: 100%;
							height: 16rem;
							margin-top: 0.95rem;
							background-color: $back;

							&.incl-image {
								background-color: $text;

								.event-title {
									color: $back;
								}
							}

							.event-image {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-size: cover;
								background-position: center;
								background-repeat: no-repeat;
								opacity: .5;
								transition: opacity 300ms;
							}
						}				
					}

					.event-info {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 16rem;
						margin-top: 0.95rem;
						background-color: transparent;
						border: 1px solid $back-hover;
						pointer-events: none;
						
						&.incl-image {
							border: none;
							color: $back;
						}

						.event-title {
							position: absolute;
							top: 50%;
							left: 0;
							width: 100%;
							height: auto;
							padding: 0.65rem 2rem;
							transform: translateY(-50%);
							transition: transform 300ms;
							text-align: center;

							*:last-child {
								margin-bottom: 0;
							}
						}

						a.tag {
							pointer-events: auto;
						}
					}

					&:hover {
						.event-back {
							.event-image {
								opacity: .25;
							}
						}

						.event-info {
							.event-title {
								transform: translateY(-55%);
							}
						}
					}
				}
			}
		}
	}
}

.blog-title + .module.events {
	& > .content {
		padding-top: 0;
	}
	
	padding-bottom: 7rem;
}

.module.navigation {
	& > .content {
		padding: 0;
		max-width: none;

		& > a.column {
			display: block;
			position: relative;
			float: left;
			width: 100%;
			max-height: 300px;
			margin: 0;
			padding: 0;
			background-color: transparent;
			transition: background-color 300ms;
			overflow: hidden;

			&:before {
				content: "";
				display: block;
				padding-top: 100%;
			}

			&.size-1-2 {
				@include min-screen(600px) {
					width: 50%;
				}
			}

			&.size-1-3 {
				@include min-screen(900px) {
					width: 33.333%;
				}
			}

			&.size-1-4 {
				@include min-screen(600px) {
					width: 50%;
				}

				@include min-screen(900px) {
					width: 25%;
				}
			}

			&.size-1-5 {
				@include min-screen(800px) {
					width: 20%;
				}
			}

			&.size-1-6 {
				@include min-screen(600px) {
					width: 33.333%;
				}

				@include min-screen(900px) {
					width: 16.666%;
				}
			}

			.background {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				opacity: .75;
				transition: opacity 300ms;
			}

			.text {
				text-align: center;
				display: block;
				width: 100%;
				height: auto;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				transition: transform 300ms;

				.icon {
					margin-bottom: 1rem;
				}
			}

			&:hover {
				.background {
					opacity: .5;
				}

				.text {
					transform: translateY(-55%);
				}
			}
		}
	}
}


.module.stickynotes {
	text-align: center;
	font-size: 0;

	.stickynotes-container {
		margin-top: -10px;
		margin-bottom: -10px;

		@include min-screen(1200px) {
			margin-top: -20px;
			margin-bottom: -20px;
		}
	}

	.stickynote {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 260px;
		height: 280px;
		margin: 15px;
		box-shadow: 2px 5px 8px 0px rgba(0,0,0,0.175);
		overflow: hidden;

		@include min-screen(1200px) {
			margin: 25px;
		}

		& > a {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		& > i.fa {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;

			font-size: 2rem;
			color: black;
			opacity: .2;
		}

		& > .text {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			max-height: 300px;
			transform: translateX(-50%) translateY(-50%);
			padding: 0 1.5rem;
			pointer-events: none;

			* {
				font-size: 1.2rem;
				line-height: 1.4em;
			}

			a {
				pointer-events: auto;
			}
		}

		transition: transform 250ms, box-shadow 250ms;

		&:hover {
			box-shadow: 2px 5px 12px -4px rgba(0,0,0,0.175);
		}

		&:nth-child(1) {
			transform: scale(1) rotate(1deg);
		}

		&:nth-child(2) {
			transform: scale(1) rotate(-3deg);
		}

		&:nth-child(3) {
			transform: scale(1) rotate(0deg);
		}

		&:hover:nth-child(1) {
			transform: scale(1.035) rotate(0deg);
		}

		&:hover:nth-child(2) {
			transform: scale(1.035) rotate(-2deg);
		}

		&:hover:nth-child(3) {
			transform: scale(1.035) rotate(1deg);
		}

		background-color: #ffffff;

		&.yellow {
			background-color: #ffffaa;
		}

		&.pink {
			background-color: #fce8f2;
		}

		&.blue {
			background-color: #d2f2f7;
		}
		
		&.green {
			background-color: #d9ffd3;
		}
	}
}















// BLOG PAGE

.blog-title {
	padding: 3rem 1rem;
	text-align: center;

	@include min-screen(1200px) {
		padding: 3rem 1.5rem;
	}

	& > h1 {
		font-size: 2rem;
		margin: 0;
	}	


	a.tag.filter {
		display: inline-block;
		position: relative;
		padding-right: 32px;
		color: $back;
		background-color: $text;
		border-color: $text;
		transition: padding-left 300ms, padding-right 300ms;

		i.fa {
			display: block;
			position: absolute;
			top: -1px;
			left: 100%;
			margin-left: -25px;
			width: 26px;
			height: 26px;
			border: 1px solid #ff4400;
			border-radius: 13px;
			background-color: #ff4400;
			padding: 0px;
			font-size: 10px;
			line-height: 23px;
			color: $text;
			background-color: $back;
			border-color: $text;
			transition: left 300ms;

			&:before {
				content: "\f078";
			}
		}

		&:hover {
			color: $text;
			background-color: $back;
			padding-left: 32px;
			padding-right: 8px;

			i.fa {
				left: 24px;
				font-size: 11px;

				&:before {
					content: "\f00d";
				}
			}
		}
	}

	.calendar-filter {
		@extend .cf;
		margin: 2.15rem auto 0 auto;
		font-size: 0;

		span, a {
			display: inline-block;
			font-size: 0.85rem;
			line-height: 1em;
			padding: 0.675em 0.85em 0.725em 0.85em;
			background-color: #e0e0e0;
			cursor: pointer;
		}

		.upcoming {
			padding-left: 1em;
			border-top-left-radius: 1.2em;
			border-bottom-left-radius: 1.2em;
		}

		.past {
			padding-right: 1em;
			border-top-right-radius: 1.2em;
			border-bottom-right-radius: 1.2em;
		}
	}

	.post-tags {
		margin: 2.15rem auto 0 auto;
	}
}

.blog-pagination {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 7rem;

	& > span {
		display: block;
		position: absolute;
		top: 3rem;
		left: 0;
		width: 100%;
		margin: 0 0 0 -1.5rem;
		text-align: center;
	}

	& > div {
		display: inline-block;
		position: absolute;
		top: 3rem;

		&.posts-prev {
			left: 1rem;
			@include min-screen(1200px) {
				left: 1.5rem;
			}
		}

		&.posts-next {
			right: 1rem;
			@include min-screen(1200px) {
				right: 1.5rem;
			}
		}
	}
}

.blog-title + .module.blogposts {
	padding-bottom: 7rem;
}


// BLOGPOST PAGE

.post-container {
	padding: 0 1rem 0 1rem;

	.post-header,
	.post-content,
	.post-share,
	.post-pagination {
		max-width: 46rem;
		margin: 0 auto;
	}

	.post-content,
	.post-share {
		border-bottom: 1px solid $back-hover;
	}

	.post-header {
		padding-top: 3.5rem;
		margin-bottom: 4rem;
		text-align: center;

		.post-tags {
			margin: 0 auto;
			width: 100%;
			max-width: 20rem;
		}

		h1 {
			margin-top: 1rem;
			margin-bottom: 1.75rem;
		}

		span.post-date {

		}
	}

	.post-content {
		padding: 0 0 3rem 0;

		font-size: 1.15rem;
		line-height: 1.4em;

		& > *:last-child {
			margin-bottom: 0;
		}
		figure {
			width: 100%;
			margin: 1.5rem auto 1.5rem auto;
		}

		img {
			display: block;
			width: auto;
			height: auto;
			max-width: 100%;
			margin: 0 auto;
		}
	}

	.post-share {
		padding: 2.5rem 0 2.65rem 0;
		text-align: center;

		span {
			display: block;
			font-size: 1.4rem;
			margin-bottom: 1.75rem;
		}

		a {
			display: inline-block;
			font-size: 1.4rem;
			line-height: 2.875rem;
			margin: 0 0.05rem;
			width: 3rem;
			height: 3rem;
			background-color: transparent;
			border-radius: 100%;
			transition: color 600ms, background-color 600ms;

			&:hover {
				color: $text-hover;
				transition: color 200ms;
			}
		}
	}

	.post-pagination {
		@extend .cf;
		padding: 3.25rem 0 3rem 0;

		.next-post {
			float: left;
		}

		.prev-post {
			float: right;
		}
	}
}
